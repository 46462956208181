.Rotating::before {
    content: "";
    background-image: conic-gradient(#04b0ee 20deg, transparent 120deg);
    height: 150%;
    width: 150%;
    position: absolute;
    left: -25%;
    top: -25%;
    animation: rotate 2s infinite linear;
}

@keyframes rotate {
    100% {
        transform: rotate(-360deg);
    }
}

.Rotating::after {
    content: "Release Candidate";
    height: 94%;
    width: 94%;
    position: absolute;
    background-color: #111827;
    border-radius: 10px;
    top: 3%;
    left: 3%;
    color: #04b0ee;
    display: grid;
    place-items: center;
}
